.image-container {
  position: relative;

  .close-icon {
    position: absolute;
    top: 4px;
    right: 4px;
  }

  .image {
    cursor: pointer;
  }
}

.upload-input-container {
  border: 1px solid #d9d9d9;
  cursor: pointer;
  padding: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  transition: all 0.3s;
  &:hover {
    border-color: #40a9ff;
    border-right-width: 1px;
  }

  #media-URL {
    display: none;
  }
}
