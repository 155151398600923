.bittus-admin-table {
  overflow-y: hidden;
  overflow-x: auto;

  .title-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    line-height: 0;
    white-space: nowrap;

    .sort-icon-container {
      display: inline-flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
