.bittus-modal {
  .ant-modal-mask {
    z-index: 1030;
  }
  .ant-modal-wrap {
    z-index: 1030;
  }
}

.ant-modal-mask {
  z-index: 1030;
}
.ant-modal-wrap {
  z-index: 1030;
}
